export type CookieBannerProps = {
  className?: string;
};

export const CookieBanner = ({ className }: CookieBannerProps) => {
  return (
    <script
      async
      id="iubenda-init"
      dangerouslySetInnerHTML={{
        __html: `
          var _iub = _iub || [];
          _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"countryDetection":true,"enableFadp":true,"enableLgpd":true,"enableUspr":true,"lang":"en","lgpdAppliesGlobally":false,"perPurposeConsent":true,"siteId":3378664,"whitelabel":false,"cookiePolicyId":62029559, "banner":{ "acceptButtonColor":"#FF5300","acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","rejectButtonCaptionColor":"#000000","rejectButtonColor":"#FFFFFF","rejectButtonDisplay":true }};
          `
      }}
    />
  );
};
