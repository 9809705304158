import { useMatches } from '@remix-run/react';
import { useMemo } from 'react';

export type RouteData = {
  isSpaceMonitor: boolean;
};

export function useRouteSpaceData(): RouteData {
  const routePartialId = 'routes/_app+';
  const matchingRoutes = useMatches();
  const route = useMemo(() => matchingRoutes.find(route => route.id.indexOf(routePartialId) > -1), [matchingRoutes]);

  if (!route) {
    return {
      isSpaceMonitor: false
    };
  }

  return {
    // @ts-ignore
    isSpaceMonitor: !route?.data?.isSpaceInternet || false
  } satisfies RouteData;
}
