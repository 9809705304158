import gsap from 'gsap';
import { useRef, type MouseEvent, useCallback } from 'react';
import useIsomorphicLayoutEffect from '#app/hooks/useIsomorphicLayoutEffect.ts';
import { useRouteSpaceData } from '#app/hooks/useRouteSpaceData.ts';

export const CustomCursor = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<HTMLDivElement>(null);
  const isClient = typeof document !== 'undefined';
  const isMobile = isClient && window.matchMedia('(max-width: 64em)').matches;
  const routeData = useRouteSpaceData();
  // console.log({ routeData });

  const onMouseMove = useCallback((e: MouseEvent) => {
    // mouseMove(e.clientX, e.clientY);
    const { target, clientX, clientY } = e;
    // @ts-ignore
    const isTargetLinkOrBtn = target?.closest('a') || target?.closest('button');

    // const body = document.body;
    // @ts-ignore
    const bounds = containerRef.current.getBoundingClientRect();
    gsap.to(cursorRef.current, {
      // @ts-ignore
      x: clientX - bounds.left - cursorRef.current.clientWidth / 2,
      // @ts-ignore
      y: clientY - bounds.top - cursorRef.current.clientHeight / 2,
      // opacity: isTargetLinkOrBtn ? 0.6 : 1,
      transform: `scale(${isTargetLinkOrBtn ? 0.7 : 1})`
    });
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isMobile) {
      return;
    }

    if (window && containerRef) {
      // @ts-ignore
      window.addEventListener('mousemove', onMouseMove);

      // const body = document.body;
      // @ts-ignore
      const bounds = containerRef.current.getBoundingClientRect();
      const x = bounds.left + bounds.width / 2;
      const y = bounds.top + bounds.height / 2;

      // Set the start position of the custom cursor
      gsap.to(cursorRef.current, {
        // @ts-ignore
        x: x - cursorRef.current.clientWidth / 2,
        // @ts-ignore
        y: isMobile ? (window.innerHeight / 5) * 3.5 : y - cursorRef.current.clientHeight / 2
      });
    }

    return () => {
      // @ts-ignore
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [isMobile, onMouseMove]);

  return (
    <>
      <div ref={containerRef} className="cursor-container" />
      <div ref={cursorRef} className={`custom-cursor ${routeData && routeData.isSpaceMonitor ? 'monitor' : ''}`}>
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="75" cy="75" r="74.5" stroke="#FF5300" />
          <circle cx="75" cy="75" r="4" fill="#FF5300" />
        </svg>
      </div>
    </>
  );
};
