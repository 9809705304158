// import { IubendaProvider, type IubendaCookieSolutionBannerConfigInterface, i18nDictionaries } from '@mep-agency/next-iubenda';
import { cssBundleHref } from '@remix-run/css-bundle';
import { type DataFunctionArgs, type HeadersFunction, json, type LinksFunction, type MetaFunction, type SerializeFrom } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useLocation } from '@remix-run/react';
import { Analytics } from '@vercel/analytics/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import rdtStylesheet from 'remix-development-tools/index.css';
import { useChangeLanguage } from 'remix-i18next';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { type ExternalScriptsHandle, ExternalScripts } from 'remix-utils/external-scripts';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { CustomCursor } from '#app/components/CustomCursor.tsx';
import i18next from '#app/i18next.server.ts';
import { CookieBanner } from './components/CookieBanner.tsx';
import { GeneralErrorBoundary } from './components/GeneralErrorBoundary.tsx';
import * as gtag from './lib/analytics.client.ts';
import globalStylesUrl from './styles/app.css';
import fontStylestylesheetUrl from './styles/fonts.css';
import { csrf } from './utils/csrf.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { makeTimings } from './utils/timing.server.ts';

// const iubendaBannerConfig: IubendaCookieSolutionBannerConfigInterface = {
//   siteId: 3378664, // Your site ID
//   cookiePolicyId: 62029559, // Your cookie policy ID
//   lang: 'en'
//   // See https://www.iubenda.com/en/help/1205-how-to-configure-your-cookie-solution-advanced-guide
// };

// let devTools = null;
// This imports the dev tools only if you're in development
// if (process.env.NODE_ENV === 'development') {
//   const { withDevTools } = await import('remix-development-tools');
//   devTools = withDevTools;
// }

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  // { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  // { rel: 'preload', href: fontStylestylesheetUrl, as: 'style' },
  { rel: 'preconnect', href: 'https://storage.googleapis.com' },
  { rel: 'preconnect', href: 'https://www.youtube.com' },
  { rel: 'preconnect', href: 'cs.iubenda.com' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: fontStylestylesheetUrl },
  // global styles
  {
    rel: 'stylesheet',
    href: globalStylesUrl
  },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null
  // process.env.NODE_ENV === 'development' ? { rel: 'stylesheet', href: rdtStylesheet } : null
  ].filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/MetaTags.html#//apple_ref/doc/uid/TP40008193-SW1
  { name: 'format-detection', content: 'telephone=no' },
  { name: 'theme-color', content: '#000000' }];

};

// export let handle = {
//   // In the handle export, we can add a i18n key with namespaces our route
//   // will need to load. This key can be a single string or an array of strings.
//   // TIP: In most cases, you should set this to your defaultNS from your i18n config
//   // or if you did not set one, set it to the i18next default namespace "translation"
//   i18n: 'common'
// };
type LoaderData = SerializeFrom<typeof loader>;
export let handle: ExternalScriptsHandle<LoaderData> = {
  scripts() {
    return [
    {
      src: 'https://cs.iubenda.com/autoblocking/3378664.js'
    },
    {
      src: 'https://cdn.iubenda.com/cs/gpp/stub.js'
    },
    {
      src: 'https://cdn.iubenda.com/cs/iubenda_cs.js'
    }];

  }
};

/**
 * ROOT Loader
 * @param request
 */
export async function loader({ request }: DataFunctionArgs) {
  let locale = await i18next.getLocale(request);
  const timings = makeTimings('root loader');
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  return json(
    {
      gaTrackingId: process.env.GA_TRACKING_ID,
      locale,
      requestInfo: {
        // hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname
      },
      ENV: getEnv(),
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders({ 'Server-Timing': timings.toString() }, csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null)
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
};

function Document({ children, locale, env = {}, gaTrackingId }: {children: React.ReactNode;gaTrackingId: string | undefined;locale: string;env?: Record<string, string>;}) {
  let { i18n } = useTranslation();

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
        <CookieBanner />
      </head>

      <body className="">
        {process.env.NODE_ENV === 'development' || !gaTrackingId ? null :
        <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />
            <script
            async
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `
            }} />

          </>}


        {/*<IubendaProvider children={undefined} bannerConfig={iubendaBannerConfig}></IubendaProvider>*/}

        <CustomCursor />
        {children}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

        <ScrollRestoration />
        <Scripts />
        <LiveReload />
        <Analytics />

        <ExternalScripts />
      </body>
    </html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const { locale, gaTrackingId } = data;
  const location = useLocation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  return (
    <Document locale={locale} env={data.ENV} gaTrackingId={gaTrackingId}>
      <Outlet />
    </Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
      <HoneypotProvider {...data.honeyProps}>
        <App />
      </HoneypotProvider>
    </AuthenticityTokenProvider>);

}

// export default withSentry(App);
// Conditionally wraps the app with the dev tools
// export default devTools ? devTools(App) : App;
// export default App;
export default AppWithProviders;

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document locale={'en'} gaTrackingId={undefined}>
      <GeneralErrorBoundary />
    </Document>);

}